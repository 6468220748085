import { useCallback, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "twin.macro";

import { useIntersectionObserver } from "../../hooks";

interface DocumentViewProps {
  fullUrl: string;
  className?: string;
}

function LazyPage({ pageNumber }: { pageNumber: number }): JSX.Element {
  const [isLoaded, setIsLoaded] = useState(false);
  const setLoaded = useCallback(() => {
    setIsLoaded(true);
  }, [setIsLoaded]);

  const { isVisible, ref } = useIntersectionObserver(setLoaded);

  return (
    <div ref={ref}>
      {isLoaded || isVisible ? (
        <Page pageNumber={pageNumber} tw="min-h-[20vh]" />
      ) : (
        <div tw="h-screen" />
      )}
    </div>
  );
}

export function DocumentView(props: DocumentViewProps): JSX.Element | null {
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  // TODO partially load pdf (currently entire file is loaded?)
  // TODO query-param pageNumber
  return (
    <Document
      tw="space-y-2"
      file={props.fullUrl}
      onLoadSuccess={onDocumentLoadSuccess}
      className={props.className}
    >
      {numPages
        ? Array.from(Array(numPages).keys()).map((p) => (
            <LazyPage key={p} pageNumber={p + 1} />
          ))
        : null}
    </Document>
  );
}
