import tw, { styled, css } from "twin.macro";
import React, { useCallback } from "react";
import { Link, To, useNavigate } from "react-router-dom";

// "Neu" refers to Neumorphism, which is a design style (not "new" in German)
// https://en.wikipedia.org/wiki/Neumorphism

export const NeuButtonBase = styled.button(({ round }: { round?: boolean }) => [
  tw`transition-all whitespace-nowrap inline-block h-10 text-center rounded align-middle px-4 py-1 cursor-pointer bg-bgbase`,
  tw`hover:(shadow-haptic-small bg-bgbase-dark text-[#0f0f0f])`,
  tw`focus:outline-none`,
  tw`disabled:(shadow-haptic-small bg-bgbase-dark text-grey-dark cursor-default)`,
  tw`border border-[#a8a8a8]`,
  // tw`bg-[#dadada]`,
  tw`bg-[#0f0f0f] text-[#f0f0f0]`,
  // css`box-shadow: -4px -4px 16px 0 rgba(255,255,255, 1), 4px 4px 16px 0 rgba(180,180,180, 0.7)`, //, 0px 0px 8px 0 rgba(180,180,180, 0.8) inset`,
  css`
    > i {
      ${tw`text-xl`}
    }
    > * {
      ${tw`align-middle`}
    }
  `,
  round ? tw`rounded-full` : null,
]);

export const RoundButton = styled.button(() => [
  tw`transition-all whitespace-nowrap inline-block h-10 text-center rounded align-middle px-4 py-1 cursor-pointer bg-bgbase rounded-full flex items-center gap-2`,
  tw`hover:(shadow-haptic-small bg-[#0f0f0f] text-[#f0f0f0] )`,
  tw`focus:outline-none`,
  tw`border border-[#a8a8a8]`,
  tw`bg-bgbase-dark text-[#0f0f0f]`,
]);

interface CheckboxRoundButtonProps {
  id: string;
  label: string;
  checked: boolean;
  onChange: () => void;
}

export const CheckboxRoundButton = ({
  id,
  label,
  checked,
  onChange,
}: CheckboxRoundButtonProps) => (
  <RoundButton>
    <label htmlFor={id}>{label}</label>
    <input type="checkbox" id={id} checked={checked} onChange={onChange} />
  </RoundButton>
);

export function BackButton({ className }: { className?: string }) {
  const navigate = useNavigate();

  return (
    <NeuButton
      className={className}
      icon="mi-arrow-left"
      round
      onClick={() => navigate(-1)}
    >
      back
    </NeuButton>
  );
}

interface NeuButtonProps {
  children?: React.ReactNode;
  className?: string;
  icon?: string;
  onClick?: (_: any) => void;
  round?: boolean;
  // URL or URL path
  to?: To;
  disabled?: any;
  type?: "submit";
}

export function NeuButton({
  to,
  icon,
  children,
  className,
  onClick,
  round,
  disabled,
  type,
}: NeuButtonProps): JSX.Element {
  const handleClick = useCallback(
    (evt) => {
      onClick && onClick(evt);
      evt?.preventDefault();
    },
    [onClick],
  );

  const btn = (
    <NeuButtonBase
      className={className}
      onClick={onClick ? handleClick : undefined}
      round={round}
      disabled={!!disabled}
      type={type}
    >
      {icon ? (
        <>
          <i tw="align-middle" className={icon} />
          &nbsp;
        </>
      ) : null}
      <span tw="align-middle">{children}</span>
    </NeuButtonBase>
  );

  return to ? <Link to={to}>{btn}</Link> : btn;
}

export const NeuInput = styled.input([
  tw`-ml-0.5 pl-11 pr-3 w-full h-12 -mt-1 rounded focus:outline-none`,
  tw`bg-[#fafafa] border border-[#a8a8a8]`,
  css`
    + i {
      ${tw`text-xl absolute left-3 top-1.5 text-grey-dark`}
    }
  `,
]);

export const MenuContainer = styled.div((props: { highlight: boolean }) => [
  tw`transition-all inline-block p-4 -mr-4 -ml-2 sm:-ml-4 pl-4 rounded rounded-md bg-bgbase max-w-[calc(100% + 2rem)] border border-bgbase`,
  props.highlight && tw`border-grey-dark md:-ml-1`,
]);
