import { useRecoilValue } from "recoil";
import { assetSharesSelector } from "../../clients/apiHooks";
import { ListLink } from "../navigation/ListLink";
import "twin.macro";

export interface AssetSharedInListProps {
  collectionId: string;
  albumId: string;
  assetId: string;
}

export default function AssetSharedInList({
  collectionId,
  albumId,
  assetId,
}: AssetSharedInListProps) {
  const assetAlbumShares = useRecoilValue(
    assetSharesSelector({
      collectionId: collectionId,
      albumId: albumId,
      assetId: assetId,
    }),
  );

  if (
    !assetAlbumShares ||
    (assetAlbumShares.albumShares.length === 0 &&
      assetAlbumShares.assetShares.length === 0)
  ) {
    return null;
  }
  return (
    <div>
      {!!assetAlbumShares.albumShares.length && (
        <div tw="py-2">
          <span tw="label">Album Shared In</span>
          {assetAlbumShares.albumShares.map((share) => (
            <ListLink
              href={`/share/${share.id}`}
              name={share.name}
              key={share.id}
              tw="py-1 px-2"
            />
          ))}
        </div>
      )}
      {!!assetAlbumShares.assetShares.length && (
        <div tw="py-2">
          <span tw="label">Shared In</span>
          {assetAlbumShares.assetShares.map((share) => (
            <ListLink
              href={`/share/${share.id}`}
              name={share.name}
              key={share.id}
              tw="py-1 px-2"
            />
          ))}
        </div>
      )}
    </div>
  );
}
