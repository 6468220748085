import "twin.macro";

import { useState, useCallback } from "react";
import { NeuButton } from "./Neu";

export interface CreateResourceInputProps {
  onSubmit: (val: string) => void;
  onChange?: (val: string) => void;
  submitOnBlur?: boolean;
  submitDisabled?: boolean;

  placeholder?: string;
  initialValue?: string;

  error?: string | JSX.Element | JSX.Element[] | null;
  cta?: string;
  className?: string;
  inputClass?: string;
}

function ResourceInput(props: CreateResourceInputProps): JSX.Element {
  const [value, setValue] = useState(props.initialValue || "");

  const { onSubmit, onChange, submitOnBlur, submitDisabled } = props;

  const handleSubmit = useCallback(
    (evt: React.FormEvent) => {
      evt.preventDefault();
      if (!submitDisabled) {
        onSubmit(value);
      }
    },
    [onSubmit, submitDisabled, value],
  );

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setValue(evt.target.value);
      if (onChange) {
        onChange(evt.target.value);
      }
    },
    [onChange],
  );

  const handleBlur = useCallback(
    (evt: React.FormEvent) => {
      if (submitOnBlur && !submitDisabled) {
        onSubmit(value);
      }
    },
    [submitOnBlur, onSubmit, submitDisabled, value],
  );

  return (
    <form className={props.className} onSubmit={handleSubmit}>
      <div tw="flex flex-col gap-y-4 items-start">
        <input
          tw="-ml-2 pl-2 focus:outline-none rounded bg-[#fafafa] border border-[#a8a8a8]"
          name="name"
          type="text"
          value={value}
          className={props.inputClass}
          autoFocus
          placeholder={props.placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {props.error ? (
          <div tw="text-warn-dark text-xl">{props.error}</div>
        ) : null}
        {props.cta ? (
          <NeuButton
            className="sm:hidden"
            type="submit"
            tw="action text-base"
            disabled={!!props.submitDisabled || !value}
          >
            {props.cta}
          </NeuButton>
        ) : null}
      </div>
    </form>
  );
}

export default ResourceInput;
