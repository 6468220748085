import { FileUpload, UploadStatus } from "../atoms";
import { SortOrder } from "../components/collection/AlbumList";
import {
  Album,
  AlbumsResult,
  Asset,
  AssetsResult,
  CollectionBase,
  GetAlbumsApiResponse,
  GetAssetsApiResponse,
  GetCollectionTagsKeysApiResponse,
  GetCollectionTagsValuesApiResponse,
  GetShareApiResponse,
  GetShareAlbumsApiResponse,
  Label,
  PatchAlbumParameters,
  Share,
  ShareQuery,
  ShareQueryUpsert,
  SharesForAsset,
  SharesResult,
  Tag,
  Workspace,
  ShareDetails,
} from "./types";

declare var __API_ROOT__: string;

export function apiUrl(path: string, prefix: string = "/api") {
  const apiRoot = (typeof __API_ROOT__ !== "undefined" && __API_ROOT__) || "";
  const normPath = `${path.startsWith("/") ? "" : "/"}${path}`;
  return `${apiRoot}${prefix}${normPath}`;
}

type CustomRequestInit = Omit<RequestInit, "body"> & {
  body?: any;
};

async function apiFetch<T>(
  path: string,
  config?: CustomRequestInit,
): Promise<T> {
  const headers = new Headers(config?.headers);
  const token = localStorage.getItem("token");
  if (token) {
    headers.set("token", token);
  }
  headers.set("Accept", "application/json");
  if (config?.body) {
    headers.set("Content-Type", "application/json");
  }

  const options: RequestInit = {
    ...config,
    headers,
  };

  if (config?.body) {
    if (config.body instanceof FormData) {
      headers.delete("Content-Type");
    } else {
      headers.set("Content-Type", "application/json");
      options.body = JSON.stringify(config.body);
    }
  }

  const response = await fetch(apiUrl(path), options);
  if (!response.ok) {
    throw new Error(`Something went wrong when calling ${path}`);
  }

  const contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    return (await response.json()) as T;
  } else {
    return (await response.text()) as unknown as T;
  }
}

export async function uploadFile(fileUpload: FileUpload) {
  if (fileUpload.state === UploadStatus.SUCCESS) {
    return; //we already tried to upload this before, skip.
  }
  const formData = new FormData();
  formData.append("upload", fileUpload.file);

  return apiFetch(
    `/collections/${fileUpload.collectionId}/albums/${fileUpload.albumId}/assets`,
    {
      method: "POST",
      body: formData,
    },
  );
}

export async function getWorkspace(): Promise<Workspace> {
  const result = await apiFetch<{
    collections: CollectionBase[];
    has_org: boolean;
  }>(`/collections`);
  return {
    collections: result.collections,
    hasOrganization: result.has_org,
  };
}

function toAlbum(collectionId: string, a: any): Album {
  return {
    name: a.name,
    id: a.id,
    collectionId,
    labels: a.labels || [],
    tags: a.tags?.map((t: any) => ({ key: t[0], value: t[1] })) || [],
    isOwner: a.is_owner ?? true,
  };
}

export async function getAlbum(
  collectionId: string,
  albumId: string,
): Promise<Album> {
  const a = await apiFetch(
    `/collections/${collectionId}/albums/${encodeURIComponent(albumId)}`,
  );
  return toAlbum(collectionId, a);
}

export async function getAlbums(
  collectionId: string,
  limit: number,
  offset: number,
  searchQuery: string,
  sortOrder: SortOrder,
  signal: AbortSignal,
): Promise<AlbumsResult> {
  let url = `/collections/${collectionId}/albums?limit=${limit}&offset=${offset}&order=${sortOrder}`;
  if (searchQuery) {
    const formattedSearchQuery = formatSearchQuery(searchQuery);
    url += `&${formattedSearchQuery}`;
  }

  const result = await apiFetch<GetAlbumsApiResponse>(url, { signal });
  const albums = result.albums.map((a) => toAlbum(collectionId, a));

  return { albums, albumsCount: result.num_results };
}

export async function getShareAlbums(
  shareId: string,
  limit: number,
  offset: number,
  sortOrder: SortOrder,
  signal: AbortSignal,
) {
  const shareAlbums = await apiFetch<GetShareAlbumsApiResponse>(
    `/shares/${shareId}/albums?limit=${limit}&offset=${offset}&order=${sortOrder}`,
    { signal },
  );

  const shareAlbumsResult = shareAlbums.albums.map((a) => {
    return {
      name: a.name,
      id: a.id,
      collectionId: a.collection_id,
      labels: a.labels || [],
      tags: a.tags?.map((t: any) => ({ key: t[0], value: t[1] })) || [],
      isOwner: a.is_owner,
    };
  });

  return {
    shareAlbums: shareAlbumsResult,
    shareAlbumsCount: shareAlbums.num_results,
  };
}

export async function getShareAssets(
  shareId: string,
  limit: number,
  offset: number,
  sortOrder: SortOrder,
  signal: AbortSignal,
) {
  const shareAssetsResponse = await apiFetch<GetAssetsApiResponse>(
    `/shares/${shareId}/assets?limit=${limit}&offset=${offset}&order=${sortOrder}`,
    { signal },
  );

  const shareAssets = shareAssetsResponse.assets.map((asset: any) =>
    toAsset(asset, shareId),
  );

  return {
    shareAssets: shareAssets,
    shareAssetsCount: shareAssetsResponse.num_results,
  };
}

export async function getCollectionTagsKeys(
  collectionId: string,
  prefix: string,
  signal?: AbortSignal,
) {
  const config = signal ? { signal } : undefined;
  return await apiFetch<GetCollectionTagsKeysApiResponse>(
    `/collections/${collectionId}/tags/keys?prefix=${prefix}`,
    config,
  );
}

export async function getCollectionTagsValues(
  collectionId: string,
  key: string,
  prefix: string,
  signal?: AbortSignal,
) {
  const config = signal ? { signal } : undefined;
  const encodedKey = encodeURIComponent(key);
  const encodedPrefix = encodeURIComponent(prefix);
  return await apiFetch<GetCollectionTagsValuesApiResponse>(
    `/collections/${collectionId}/tags/values?key=${encodedKey}&prefix=${encodedPrefix}`,
    config,
  );
}

export async function createAlbum(
  collectionId: string,
  name: string,
): Promise<Album> {
  return apiFetch(`/collections/${collectionId}/albums`, {
    method: "POST",
    body: { name, tags: [], labels: [] },
  });
}

export async function deleteAlbum(
  collectionId: string,
  albumId: string,
): Promise<null> {
  await apiFetch(`/collections/${collectionId}/albums/${albumId}`, {
    method: "DELETE",
  });
  return null;
}

export async function deleteCollection(collectionId: string): Promise<null> {
  await apiFetch(`/collections/${collectionId}`, {
    method: "DELETE",
  });
  return null;
}

function toAsset(asset: any, shareId?: string): Asset {
  const imageLink = shareId
    ? `/share/${shareId}/c/${asset.collection_id}/a/${asset.album_id}/s/${asset.id}`
    : `/collection/${asset.collection_id}/a/${asset.album_id}/s/${asset.id}`;
  return {
    ...asset,
    isOwner: asset.is_owner ?? true,
    url: asset.path && apiUrl(asset.path, ""),
    link: imageLink,
    tags: asset.tags.map((t: [string, string]) => ({
      key: t[0],
      value: t[1],
    })),
  };
}

const formatSearchQuery = (searchQuery: string): string => {
  let tags: string[] = [];
  let queries: string[] = [];

  const splitedQueries = searchQuery.trim().split(/\s+/);
  splitedQueries.forEach((query) => {
    if (query.includes("!=") || query.includes("=") || query.startsWith("#")) {
      tags.push(query);
    } else {
      queries.push(query);
    }
  });

  const encodedTags = tags.map((tag) => `tag=${encodeURIComponent(tag)}`);
  const encodedQueries = queries.map(
    (query) => `query=${encodeURIComponent(query)}`,
  );

  return [...encodedTags, ...encodedQueries].join("&");
};

export async function getAssets(
  collectionId: string,
  albumId: string | undefined,
  searchQuery: string | undefined,
  limit: number,
  offset: number,
  sortOrder: SortOrder = "ASC",
  signal?: AbortSignal,
  shareId?: string,
): Promise<AssetsResult> {
  let url = `/collections/${collectionId}/assets?order=${sortOrder}&limit=${limit}&offset=${offset}`;
  if (albumId) {
    url += `&album=${albumId}`;
  }
  if (searchQuery) {
    const formattedSearchQuery = formatSearchQuery(searchQuery);

    url += `&${formattedSearchQuery}`;
  }
  const config = signal ? { signal } : undefined;
  const assetResponse = await apiFetch<GetAssetsApiResponse>(url, config);
  const assets = assetResponse.assets.map((asset: any) =>
    toAsset(asset, shareId),
  );
  return { assets, assetsCount: assetResponse.num_results };
}

export async function getAsset(
  collectionId: string,
  albumId: string,
  assetId: string,
): Promise<Asset> {
  const url = `/collections/${collectionId}/albums/${albumId}/assets/${assetId}`;
  const asset = await apiFetch(url);
  return toAsset(asset);
}

export async function updateAssetMetadata(
  newValues: { labels?: Label[]; tags?: Tag[]; name?: String },
  collectionId: string,
  albumId: string,
  assetId: string,
) {
  const newTags = newValues.tags?.map((t) => [t.key, t.value]);
  return apiFetch(
    `/collections/${collectionId}/albums/${albumId}/assets/${assetId}`,
    {
      method: "PATCH",
      body: {
        labels: newValues.labels,
        tags: newTags,
        name: newValues.name,
      },
    },
  );
}

export async function deleteAsset(
  collectionId: string,
  albumId: string,
  assetId: string,
): Promise<null> {
  await apiFetch(
    `/collections/${collectionId}/albums/${albumId}/assets/${assetId}`,
    { method: "DELETE" },
  );
  return null;
}

export async function createCollection(name: string): Promise<CollectionBase> {
  return await apiFetch(`/collections`, {
    method: "POST",
    body: { name },
  });
}

export async function patchCollection(
  name: string,
  collectionId: string,
): Promise<CollectionBase> {
  return await apiFetch(`/collections/${collectionId}`, {
    method: "POST",
    body: { name },
  });
}

export async function patchAlbum(
  collectionId: string,
  albumId: string,
  newValues: PatchAlbumParameters,
) {
  const payload: {
    labels?: string[];
    tags?: [string, string][];
    name?: string;
  } = {};
  if (newValues.tags) {
    payload.tags = newValues.tags.map(({ key, value }) => [key, value]);
  }
  if (newValues.labels) {
    payload.labels = newValues.labels;
  }
  if (newValues.name) {
    payload.name = newValues.name;
  }
  return apiFetch(`/collections/${collectionId}/albums/${albumId}`, {
    method: "PATCH",
    body: payload,
  });
}

export async function createShare(name: string): Promise<Share> {
  return await apiFetch(`/shares`, {
    method: "POST",
    body: { name },
  });
}

export async function patchShare(
  name: string,
  shareId: string,
): Promise<CollectionBase> {
  return await apiFetch(`/shares/${shareId}`, {
    method: "PATCH",
    body: { name },
  });
}

export async function deleteShare(shareId: string): Promise<null> {
  await apiFetch(`/shares/${shareId}`, {
    method: "DELETE",
  });
  return null;
}

export async function upsertShareQuery(shareQuery: ShareQueryUpsert) {
  return await apiFetch(`/shares/${shareQuery.shareId}/query`, {
    method: "POST",
    body: {
      share_query_id: shareQuery.shareQueryId,
      collection_id: shareQuery.collectionId,
      share_queries: shareQuery.query,
    },
  });
}

export async function deleteShareQuery(shareQueryId: string, shareId: string) {
  return await apiFetch(`/shares/${shareId}/query/${shareQueryId}`, {
    method: "DELETE",
  });
}

export async function getShares(): Promise<SharesResult> {
  return await apiFetch(`/shares`);
}

export async function getShare(shareId: string): Promise<ShareDetails> {
  const {
    share_queries,
    sharee_emails: sharees,
    is_owner,
    ...share
  } = await apiFetch<GetShareApiResponse>(`/shares/${shareId}`);

  let queries: ShareQuery[] = share_queries.map((query) => {
    return {
      shareQueryId: query.id,
      query: query.query,
      shareId: query.share_id,
      collectionId: query.collection_id,
    };
  });

  return { ...share, queries, sharees, isOwner: is_owner };
}

export async function inviteToShare(emails: string[], shareId: string) {
  return await apiFetch(`/shares/${shareId}/sharees`, {
    method: "POST",
    body: { emails },
  });
}

export async function unInviteFromShare(email: string, shareId: string) {
  return await apiFetch(`/shares/${shareId}/sharees/${email}`, {
    method: "DELETE",
  });
}

export async function getSharesForUser(): Promise<Share[]> {
  let result = await apiFetch("/shares/user");

  if (!result) {
    return [];
  }

  return result as Share[];
}

export async function getSharesForAlbum(
  collectionId: string,
  albumId: string,
): Promise<Share[]> {
  let result = await apiFetch(
    `/collections/${collectionId}/albums/${albumId}/shares`,
  );

  if (!result) {
    return [];
  }

  return result as Share[];
}

export async function getSharesForAsset(
  collectionId: string,
  albumId: string,
  assetId: string,
): Promise<SharesForAsset> {
  let result: { album_shares: []; asset_shares: [] } = await apiFetch(
    `/collections/${collectionId}/albums/${albumId}/assets/${assetId}/shares`,
  );

  if (!result) {
    return { albumShares: [], assetShares: [] };
  }

  return {
    albumShares: result?.album_shares,
    assetShares: result?.asset_shares,
  };
}
