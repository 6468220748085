import "twin.macro";
import { Link, useSearchParams } from "react-router-dom";

export type PaginationProps = {
  pages: number;
  className?: string | undefined;
  param?: string;
};

export function Pagination(props: PaginationProps) {
  const [params] = useSearchParams();
  if (props.pages <= 1) {
    return null;
  }
  const currentPage = getPage(params, props.param ?? "page");
  const pages = Array(props.pages)
    .fill(null)
    .map((_, i) => i);
  return (
    <div tw="space-x-4" className={props.className}>
      {pages.map((page) => (
        <Link
          key={page}
          to={
            "?" + changeParam(params, props.param ?? "page", String(page + 1))
          }
          style={{
            textDecoration: page + 1 === currentPage ? "underline" : undefined,
          }}
        >
          {page + 1}
        </Link>
      ))}
    </div>
  );
}

export function getPage(params: URLSearchParams, param = "page"): number {
  return getNumberParam(params, param, 1);
}

function changeParam(
  params: URLSearchParams,
  name: string,
  newValue: string,
): string {
  const newParams = new URLSearchParams(params);
  newParams.set(name, newValue);
  return newParams.toString();
}

function getNumberParam(
  params: URLSearchParams,
  name: string,
  defaultValue: number,
): number {
  const param = params.get(name);
  if (param === null) {
    return defaultValue;
  }
  const parsed = parseInt(param);
  if (isNaN(parsed)) {
    return defaultValue;
  }
  return parsed;
}
