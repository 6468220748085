import "twin.macro";
import React, { Suspense, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  AlbumContext,
  CollectionContext,
  useAlbumHook,
  useAlbumsHook,
  useSelectedAssets,
  albumSelector,
  useAssetsRefresh,
} from "../../clients/apiHooks";
import { useIntersectionObserver } from "../../hooks";

import { Modal } from "../generic";
import ErrorBoundary from "../generic/ErrorBoundary";
import { MenuContainer, NeuButton } from "../generic/Neu";

import Uploads from "./Uploads";
import { UploadList } from "./UploadList";
import { ItemGroupContainer, TagItem } from "../tags/Items";
import { EditableTitle } from "./EditableTitle";
import { pageSize, resetPageParam, SortOrder } from "../collection/AlbumList";
import { Helmet } from "react-helmet";
import { getAssets } from "../../clients/apiClient";
import { getPage, Pagination } from "../generic/Pagination";
import SearchInput from "../generic/SearchInput";
import { AssetsResult } from "../../clients/types";
import AlbumsSharedWith from "./AlbumSharedInList";
import { AssetsDisplayList } from "../generic/DisplayList";

function AlbumViewInner({ shareId }: { shareId?: string }) {
  const { collectionId, albumId } = useParams<{
    collectionId: string;
    albumId: string;
  }>();
  const [searchParams, setSearchParams] = useSearchParams();
  // FIXME: the way the query params work for pagination is kind of a mess because the album view still also has the album list
  const page = getPage(searchParams, "assetPage");
  const id = albumId;
  const [showUpload /*, setShowUpload*/] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { getSelectedAssets } = useSelectedAssets();
  const selectedAssets = getSelectedAssets();
  const [sortOrder, setSortOrder] = useState<SortOrder>("ASC");
  const navigate = useNavigate();

  const { patchAlbum } = useAlbumHook(collectionId!, albumId!);
  const { deleteAlbum } = useAlbumsHook(collectionId!);
  const album = useRecoilValue(
    albumSelector({ collectionId: collectionId!, albumId: albumId! }),
  );
  const [assets, setAssets] = useState<AssetsResult>({
    assets: [],
    assetsCount: 0,
  });

  const [isLoading, setIsLoading] = useState(true);

  const assetsRefresh = useAssetsRefresh();
  const pages = Math.max(Math.ceil(assets.assetsCount / pageSize), 1);

  const { isVisible } = useIntersectionObserver();

  const handleNewTag = album.isOwner
    ? async (update: { key: string; value: string }) => {
        if (!album) {
          return;
        }
        const dataPayload = {
          tags: [
            ...(album.tags || []),
            { key: update.key, value: update.value },
          ],
        };
        await patchAlbum(dataPayload);
      }
    : undefined;

  const handleUpdateAlbumName = album.isOwner
    ? async (newAlbumName: string) => {
        if (!album) {
          return;
        }
        const dataPayload = {
          name: newAlbumName,
        };
        await patchAlbum(dataPayload);
      }
    : undefined;

  async function handleDeleteAlbum() {
    if (!album) {
      return;
    }
    await deleteAlbum(album.id);
    navigate(`/collection/${collectionId}`);
  }

  const handleUpdateTag = album.isOwner
    ? (
        tag: { key: string; value: string },
        update: { key: string; value: string },
      ) => {
        if (!album) {
          return;
        }
        const dataPayload = {
          tags: album.tags.map((t) =>
            t.key === tag.key && t.value === tag.value
              ? { key: update.key, value: update.value }
              : t,
          ),
        };
        patchAlbum(dataPayload);
      }
    : undefined;

  const handleDeleteTag = album.isOwner
    ? (tag?: { key: string; value: string }) => {
        if (!album) {
          return;
        }
        const dataPayload = {
          tags: album.tags.filter(
            (t) => t.key !== tag?.key || t.value !== tag?.value,
          ),
        };
        patchAlbum(dataPayload);
      }
    : undefined;

  useEffect(() => {
    resetPageParam({ searchParams, setSearchParams, name: "assetPage" });
  }, [searchQuery]);

  useEffect(() => {
    setIsLoading(true);
    const abortController = new AbortController();
    (async () => {
      try {
        if (collectionId) {
          const fetchedAssets = await getAssets(
            collectionId,
            albumId,
            searchQuery,
            pageSize,
            (page - 1) * pageSize,
            sortOrder,
            abortController.signal,
            shareId,
          );
          setAssets(fetchedAssets);
          setIsLoading(false);
        }
      } catch (error) {
        if (error instanceof Error && error.name === "AbortError") {
          // No-op if request aborted
        } else {
          console.error("Error fetching assets:", error);
          setIsLoading(false);
        }
      }
    })();

    return () => abortController.abort();
  }, [collectionId, albumId, searchQuery, sortOrder, assetsRefresh, page]);

  if (!album || !albumId || !collectionId) {
    return null;
  }

  return (
    <ErrorBoundary fallback={<div>something went wrong loading albums...</div>}>
      <Helmet>
        <title>{album.name} | Exrepo</title>
      </Helmet>
      <CollectionContext.Provider value={collectionId ?? null}>
        {selectedAssets.length ? (
          <div>{selectedAssets.length} selected</div>
        ) : null}
        <AlbumContext.Provider value={id ?? null}>
          <div tw="max-w-[1440px]">
            {showUpload && (
              <Modal>
                <Uploads
                  albumId={album?.id}
                  collectionId={album?.collectionId}
                />
              </Modal>
            )}
            <div tw="sm:space-y-12">
              <div id="headerDiv" tw="flex flex-row justify-between">
                <div tw="w-full">
                  <span tw="label">Album</span>
                  <div tw="flex flex-col sm:flex-row gap-4 justify-between w-full">
                    <div tw="text-3xl sm:text-7xl">
                      <EditableTitle
                        title={album.name}
                        onUpdate={handleUpdateAlbumName}
                      />
                    </div>
                    <NeuButton
                      tw="bg-danger text-sm mb-6 sm:mb-0 mr-auto sm:mr-0"
                      icon="mi-delete"
                      disabled={!!assets.assets.length || isLoading}
                      onClick={handleDeleteAlbum}
                    >
                      Delete Album
                    </NeuButton>
                  </div>
                </div>
                {/*
                <div>
                  <UploadList
                    current={false}
                    albumId={albumId}
                    collectionId={album?.collectionId}
                  />
                </div>
                */}
              </div>
              <div tw="space-y-2 mb-4">
                <span tw="label mb-4">Tags</span>
                <ItemGroupContainer tw="-ml-1">
                  {album.labels?.map((label) => (
                    <TagItem
                      key={"#" + label}
                      tag={{ key: "#", value: label }}
                      onUpdate={handleUpdateTag}
                      onDelete={handleDeleteTag}
                    />
                  ))}
                  {album.tags?.map((t) => (
                    <TagItem
                      key={t.key + t.value}
                      tag={t}
                      onUpdate={handleUpdateTag}
                      onDelete={handleDeleteTag}
                    />
                  ))}
                  <TagItem key="new-tag-input" onCreate={handleNewTag} />
                </ItemGroupContainer>
              </div>
              {album.isOwner && (
                <Suspense fallback={<div>Loading Shared With...</div>}>
                  <AlbumsSharedWith
                    collectionId={collectionId}
                    albumId={albumId}
                  />
                </Suspense>
              )}
              <div>
                <div tw="mb-4 sticky top-12 z-10">
                  <MenuContainer highlight={!isVisible}>
                    <div tw="flex flex-col sm:flex-row gap-4 sm:gap-x-8 w-full">
                      <SearchInput
                        collectionId={collectionId}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                      />
                      <div tw="flex gap-4">
                        <NeuButton
                          onClick={() =>
                            setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")
                          }
                        >
                          <i className="mi-sort" />
                          Sort
                        </NeuButton>
                        <Uploads
                          albumId={album?.id}
                          collectionId={album?.collectionId}
                        />
                      </div>
                    </div>
                    <UploadList
                      current
                      albumId={albumId}
                      collectionId={collectionId}
                    />
                  </MenuContainer>
                </div>
                <div>
                  <AssetsDisplayList
                    assets={assets.assets}
                    searchQuery={searchQuery}
                    isLoading={isLoading}
                  />
                  <Pagination pages={pages} param="assetPage" tw="mt-4" />
                </div>
              </div>
            </div>
          </div>
        </AlbumContext.Provider>
      </CollectionContext.Provider>
    </ErrorBoundary>
  );
}

function AlbumView({ shareId }: { shareId?: string }) {
  return (
    <ErrorBoundary fallback={<div>something went wrong loading albums...</div>}>
      <AlbumViewInner shareId={shareId} />
    </ErrorBoundary>
  );
}

export default React.memo(AlbumView);
