import "twin.macro";
import React, { useState } from "react";

import { useCallback, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";

import { useAsset } from "../../clients/apiHooks";

import ErrorBoundary from "../generic/ErrorBoundary";
import { DocumentView } from "./DocumentView";
import { BackButton, NeuButton } from "../generic/Neu";

import { TagItem, ItemGroupContainer } from "../tags/Items";
import { EditableTitle } from "./EditableTitle";
import { Helmet } from "react-helmet";
import { AlbumWithAssets } from "../../clients/types";
import AssetSharedInList from "./AssetSharedInList";
import ConfirmDialog from "../share/confirmDialog";

export interface AssetDetailsProps {
  id: string;
  albumId?: string;
  collectionId?: string;
  album?: AlbumWithAssets | null;
  className?: string;
  onSelect: () => void;
  onClose: () => void;
  showDeleteDialogOnNextDelete?: boolean;
  setShowDeleteDialogOnNextDelete?: (
    showDeleteDialogOnNextDelete: boolean,
  ) => void;
  hideDeleteButton?: boolean;
}

function AssetDetails(props: AssetDetailsProps) {
  const navigate = useNavigate();
  const handleKeyPress = useCallback(
    (event) => {
      const album = props.album;
      if (album?.assets?.length) {
        const currIdx = album?.assets?.findIndex(
          (ast: any) => ast.id === props.id,
        );
        const prevIdx = currIdx === 0 ? album.assets.length - 1 : currIdx - 1;
        const nextIdx = currIdx === album.assets.length - 1 ? 0 : currIdx + 1;
        const prev = album.assets[prevIdx];
        const next = album.assets[nextIdx];
        event.key === "ArrowRight" &&
          navigate(
            `/collection/${album.collectionId}/a/${album.id}/s/${next.id}`,
          );
        event.key === "ArrowLeft" &&
          navigate(
            `/collection/${album.collectionId}/a/${album.id}/s/${prev.id}`,
          );
      }
    },
    [props, navigate],
  );

  useEffect(() => {
    // FIXME this is also triggered when moving a cursor inside an input...
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className={props.className} tw="flex flex-col sm:flex-row h-full">
      <div tw="flex-grow h-full order-2 sm:order-1 relative justify-center overflow-auto">
        <ErrorBoundary
          fallback={
            <div tw="flex items-center justify-center h-screen">
              <div>Something went wrong loading the asset...</div>
            </div>
          }
        >
          <LeftSide {...props} />
        </ErrorBoundary>
      </div>
      <div tw="flex-none order-1 sm:order-2 w-full sm:w-1/2 xl:w-1/3 bg-bgbase px-3 py-4 space-y-2 sm:space-y-16 overflow-auto">
        <BackButton tw="mb-6" />
        <ErrorBoundary
          fallback={<div>Something went wrong loading the asset...</div>}
        >
          <RightSide {...props} />
        </ErrorBoundary>
      </div>
    </div>
  );
}

function LeftSide(props: AssetDetailsProps) {
  const { asset } = useAsset({
    assetId: props.id,
    albumId: props.albumId,
    collectionId: props.collectionId,
  });
  let pdfUrl: string | boolean = false;

  if (!asset) throw new Error("No asset");

  if (asset.url && asset.url.match(/(\.pdf)$/i)) {
    pdfUrl = asset.url.replace("/i/", "/o/");
  }

  return (
    <>
      {typeof pdfUrl === "string" && (
        <div tw="items-center flex flex-col">
          <DocumentView fullUrl={pdfUrl} />
        </div>
      )}{" "}
      {!pdfUrl && (
        <img
          src={`/i/${asset.collection_id}/${asset.album_id}/${asset.id}/${asset.id}?w=1000&h=1000`}
          alt={asset.id}
          tw="max-h-[98%] absolute top-1/2 left-1/2 transform translate-x--1/2 translate-y--1/2"
        />
      )}
    </>
  );
}

function RightSide(props: AssetDetailsProps) {
  const { asset, deleteAsset, updateAssetMetadata } = useAsset({
    assetId: props.id,
    albumId: props.albumId,
    collectionId: props.collectionId,
  });

  if (!asset) throw new Error("No asset");

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSkipDeleteDialog, setIsSkipDeleteDialog] = useState(false);

  const handleDelete = () => deleteAsset().then(() => props.onClose());

  const handleNewTag = asset.isOwner
    ? async (update: { key: string; value: string }) => {
        if (!asset) {
          return;
        }
        const dataPayload = {
          tags: [...asset.tags, { key: update.key, value: update.value }],
        };
        await updateAssetMetadata(dataPayload);
      }
    : undefined;

  const handleUpdateName = asset.isOwner
    ? async (title: string) => {
        if (!asset) {
          return;
        }
        const dataPayload = {
          name: title,
        };
        await updateAssetMetadata(dataPayload);
      }
    : undefined;

  const handleUpdateTag = asset.isOwner
    ? (
        tag: { key: string; value: string },
        update: { key: string; value: string },
      ) => {
        if (!asset) {
          return;
        }
        const dataPayload = {
          tags: asset.tags.map((t) =>
            t.key === tag.key && t.value === tag.value
              ? { key: update.key, value: update.value }
              : t,
          ),
        };
        updateAssetMetadata(dataPayload);
      }
    : undefined;

  const handleDeleteTag = asset.isOwner
    ? (tag?: { key: string; value: string }) => {
        if (!asset) {
          return;
        }
        const dataPayload = {
          tags: asset.tags.filter(
            (t) => t.key !== tag?.key || t.value !== tag?.value,
          ),
        };
        updateAssetMetadata(dataPayload);
      }
    : undefined;

  return (
    <>
      <Helmet>
        <title>{asset.name} | Exrepo</title>
      </Helmet>
      <div>
        <span tw="label mb-4">Name</span>
        <h2 tw="break-all">
          {" "}
          <EditableTitle title={asset.name} onUpdate={handleUpdateName} />
        </h2>
      </div>
      {props.showDeleteDialogOnNextDelete && (
        <ConfirmDialog
          isOpen={isDeleteDialogOpen}
          onAccept={() => {
            if (isSkipDeleteDialog) {
              props.setShowDeleteDialogOnNextDelete &&
                props.setShowDeleteDialogOnNextDelete(false);
            }
            handleDelete();
          }}
          onDecline={() => setIsDeleteDialogOpen(false)}
          body={
            <>
              Are you sure you want to delete this asset?
              <br /> This action cannot be undone.
              <div tw="flex items-center justify-center mt-5">
                <input
                  type="checkbox"
                  id="confirm-dialog-checkbox"
                  tw="mr-2"
                  checked={isSkipDeleteDialog}
                  onChange={() => setIsSkipDeleteDialog((prev) => !prev)}
                />
                <label htmlFor="confirm-dialog-checkbox">
                  Do not ask me again in this album
                </label>
              </div>
            </>
          }
          confirmText={"Delete asset"}
          declineText={"Cancel"}
          onClose={() => setIsDeleteDialogOpen(false)}
        />
      )}
      <div>
        <span tw="label mb-4">Tags</span>
        <ItemGroupContainer>
          {asset.labels.map((l) => (
            <TagItem
              key={"#" + l}
              tag={{ key: "#", value: l }}
              onUpdate={handleUpdateTag}
              onDelete={handleDeleteTag}
            />
          ))}
          {asset.tags.map((t) => (
            <TagItem
              key={t.key + t.value}
              tag={t}
              onUpdate={handleUpdateTag}
              onDelete={handleDeleteTag}
            />
          ))}
          <TagItem key="new-tag-input" onCreate={handleNewTag} />
          {asset.labels?.length || asset.tags?.length ? (
            <span tw="ml-0" />
          ) : null}
        </ItemGroupContainer>
        {asset.album_labels?.length || asset.album_tags?.length ? (
          <>
            <span tw="label font-normal mt-6 mb-4">
              Tags inherited from album
            </span>
            <ItemGroupContainer>
              {asset.album_labels?.map((l) => (
                <TagItem key={"#" + l} tag={{ key: "#", value: l }} />
              ))}
              {asset.album_tags?.map((t: any) => (
                <TagItem key={t[0] + t[1]} tag={{ key: t[0], value: t[1] }} />
              ))}
            </ItemGroupContainer>
          </>
        ) : null}
      </div>
      <div tw="flex space-x-8">
        <a href={asset.download_path} download>
          <NeuButton icon="mi-download">Download</NeuButton>
        </a>
        {asset.isOwner && !props.hideDeleteButton && (
          <NeuButton
            icon="mi-delete"
            tw="bg-[#ce4040]"
            onClick={() => {
              if (props.showDeleteDialogOnNextDelete) {
                setIsDeleteDialogOpen(true);
              } else {
                handleDelete();
              }
            }}
          >
            Delete
          </NeuButton>
        )}
      </div>
      {props.collectionId && props.albumId && asset.isOwner && (
        <div>
          <AssetSharedInList
            collectionId={props.collectionId}
            albumId={props.albumId}
            assetId={props.id}
          />
        </div>
      )}
    </>
  );
}

export default memo(AssetDetails);
