import tw, { styled } from "twin.macro";

import { useCallback, useEffect } from "react";

import { BaseProps } from "./BaseProps";
import { NeuButton } from "./Neu";

export const Backdrop = tw.div`fixed top-0 left-0 w-full h-full bg-grey-lightest bg-opacity-95 backdrop-blur-sm`;

export interface ModalProps {
  className?: string;
  children?: JSX.Element | JSX.Element[] | null;
  centered?: boolean | null;
  onClose?: () => void | null;
}

const ContentWrapper = styled.div((props: ModalProps) => [
  props.centered
    ? tw`inline-block absolute top-1/2 left-1/2 transform translate-x--1/2 translate-y--1/2 max-w-full overflow-auto`
    : tw`w-full h-full relative`,
]);

export const Modal = function (props: ModalProps): JSX.Element {
  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Escape" && props.onClose) {
        props.onClose();
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [props],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div tw="fixed top-0 left-0 w-full h-full overflow-hidden">
      <Backdrop onClick={props.onClose} />
      <ContentWrapper centered={props.centered} className={props.className}>
        {props.children}
      </ContentWrapper>
      {props.onClose && (
        <div tw="fixed top-0 right-0 p-4 text-center" onClick={props.onClose}>
          <NeuButton round icon="mi-close">
            close
          </NeuButton>
        </div>
      )}
    </div>
  );
};

export interface IconProps {
  t: string;
}

export function Icon(props: BaseProps & IconProps): JSX.Element {
  return (
    <i className={`mi-${props.t} ${props.className}`} onClick={props.onClick} />
  );
}
