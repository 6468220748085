import "twin.macro";

import { useMatch, useNavigate } from "react-router-dom";
import { redirectToAuth } from "supertokens-auth-react";

import { NeuButton } from "./generic/Neu";
import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";

function LandingPage(): JSX.Element {
  const [checkedSession, setCheckedSession] = useState(false);
  const navigate = useNavigate();

  const routeMatch = useMatch({
    path: "/",
  });

  const checkSession = async () => {
    if (await Session.doesSessionExist()) {
      let userId = await Session.getUserId();
      if (userId !== undefined && routeMatch) {
        navigate(`/collection/`, { replace: true });
      }
    }
    setCheckedSession(true);
  };
  useEffect(() => {
    if (!checkedSession) {
      checkSession();
    }
  }, [navigate, routeMatch]);

  return (
    <div tw="w-full max-w-screen-md mx-auto py-20">
      <h1>
        This is <b>XR</b>
      </h1>
      <p>
        <b>XR</b> is the digital asset management platform for corporations,
        agencies and individual professionals.
      </p>
      <p>
        As your central storage, management and exchange facility, it's the
        single source of truth for all your files, product pictures, documents,
        photos and multi-media assets.
      </p>
      <p>
        At the same time, <b>XR</b> is built as your exchange hub with external
        partners and is designed to integrate seamlessly with your existing
        IT-ecosystem.
      </p>

      <h2 tw="mt-12">Managing Assets</h2>
      <p>
        <b>XR</b> allows you to organize your digital assets into collections of
        albums. This gives you sufficient granularity to divide and conquer huge
        amounts of files without getting lost in the ever so deep rabbit holes
        of folder structures.
      </p>
      <p>
        You can mark your folders as well as individual assets with tags or
        assign attributes to them, conveying additional meta information about
        the album or assets as necessary. In addition, <b>XR</b> allows you to
        enforce company wide rules for labels and attributes so that everyone is
        on the same page regarding your data.
      </p>
      <p>
        It includes a powerful search and filtering system that let's you easily
        and quickly find the exact assets that you're looking for.
      </p>

      <h2 tw="mt-12">External Sharing</h2>
      <p>
        Share individual assets, selections or entire albums internally in your
        organization, or with your external partners, be it creative agencies,
        customers or distributors.
      </p>
      <p>
        Shares are created and managed directly inside <b>XR</b>, so no need to
        copy files to a DropBox, WeTransfer, OneDrive, SharePoint or any other
        external system. Provide read-only or read/write access, with or without
        password protection.
      </p>
      <p>
        Once you've created a share for an external partner, you can keep
        reusing it by simply adding additional files and albums to it. Never
        again send links back and forth or forget what has been shared with whom
        with which link. Additionally, shares can be accessed via FTP or
        REST-API for a more programmatic and integrated communication scheme.
      </p>

      <h2 tw="mt-12">Connecting other Solutions</h2>
      <p>
        Connect <b>XR</b> to your PIM, eCommerce-solution or CMS to access your
        central asset storage directly, all without tedious and time-costly
        downloading, copying, renaming, or uploading thousands of files.
      </p>
      <p>
        Benefit from <b>XR</b>'s powerful search and filtering and your own
        collection and album structure to quickly find the assets you need
        directly in your target system. If your system is capable, you can even
        auto-link assets to target entities based on asset labels and
        attributes.
      </p>
      <p>
        Ever wanted to switch your PIM, shop or WordPress to a different system
        or provider and had to somehow magically move all your assets? Not with{" "}
        <b>XR</b>! Just connect your target system with <b>XR</b> and continue
        where you left off.
      </p>

      <h2 tw="mt-12">Technical Integration</h2>
      <p>
        <b>XR</b> integrates with the most well known cloud provider storage
        backends (Azure, AWS, Google) as well as on-premise storage systems,
        this gives you the flexibility to store your collections wherever it
        suits you best!
      </p>
      <p>
        When it comes time to deliver assets to your users, <b>XR</b> plays well
        with modern frameworks like NextJs, Gatsby and many more. Assets are
        served through a state of the art CDN including on-demand resizing and
        re-formatting coupled with blazingly fast caching.
      </p>
      <p>
        To integrate well with your companies infrastructure, <b>XR</b> is also
        able to be accessed in NAS-fashion, as well as through a REST-API or
        FTP.
      </p>

      <p tw="text-center">
        <NeuButton
          onClick={() => {
            redirectToAuth({ redirectBack: false });
          }}
          tw="text-white hover:bg-positive-dark bg-positive-darkest"
        >
          Login
        </NeuButton>
      </p>
    </div>
  );
}

export default LandingPage;
