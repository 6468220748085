import { atom } from "recoil";

export enum UploadStatus {
  NOT_STARTED = "NOT_STARTED",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  IN_PROGRESS = "IN_PROGRESS",
}

export interface FileUpload {
  file: File;
  albumId: string;
  collectionId: string;
  state: UploadStatus;
  id: string;
}

export const activeUploads = atom({
  key: "UploadList",
  default: [] as FileUpload[],
});

export const selectedAssetDictionary = atom({
  key: "SelectedAssets",
  default: {} as { [key: string]: { isSelected: boolean } },
});
