import { useEffect, useRef, useState } from "react";

export function useIntersectionObserver(onVisible?: () => void) {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const containerObserver = new IntersectionObserver((entries, observer) => {
      if (entries[0]) {
        setIsVisible(entries[0].isIntersecting);
        entries[0].isIntersecting && onVisible && onVisible();
      }
    });

    const observedNode: Element | null = containerRef.current;
    if (observedNode) {
      containerObserver.observe(observedNode);
    }

    return () => {
      if (observedNode) {
        containerObserver.unobserve(observedNode);
      }
    };
  }, [containerRef, setIsVisible, onVisible]);

  return {
    ref: containerRef,
    isVisible,
  };
}
