import "twin.macro";

import React, { useCallback } from "react";
import ResourceInput from "../generic/ResourceInput";
import { useAlbumsHook, useCollection } from "../../clients/apiHooks";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../generic/Neu";

function NewAlbumView(): JSX.Element {
  const { collectionId } = useParams<{ collectionId: string }>();
  const { collection } = useCollection(collectionId!);
  const { createAlbum } = useAlbumsHook(collectionId!);

  const navigate = useNavigate();
  const handleSubmit = useCallback(
    (val) => {
      createAlbum(val).then((album) =>
        navigate(`/collection/${collectionId}/a/${album.id}`),
      );
    },
    [createAlbum, navigate, collectionId],
  );

  return (
    <div tw="max-w-[1440px]">
      <BackButton tw="mb-8" />
      <span tw="label mb-4">New Album</span>
      <ResourceInput
        tw="mb-4"
        placeholder="New album name"
        inputClass="text-4xl sm:text-6xl w-full"
        onSubmit={handleSubmit}
        cta="Create"
      />
      <div>
        This will create a new album in the collection{" "}
        <span tw="font-bold">{collection?.name}</span>. You can add tags, labels
        and assets after its creation.
      </div>
    </div>
  );
}

export default NewAlbumView;
