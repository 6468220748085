import "twin.macro";

import { Link } from "react-router-dom";
import { ListLink } from "./ListLink";
import { NeuButton } from "../generic/Neu";
import { useWorkspace } from "../../clients/apiHooks";

function CollectionList() {
  const { collections, hasOrganization, loading, error: err } = useWorkspace();

  return (
    <div tw="p-4 w-72">
      {hasOrganization && <span tw="label">Collections</span>}
      {loading && <div>loading collections...</div>}
      {err && (
        <div>sorry, something went wrong fetching the collections...</div>
      )}
      <div tw="divide-y divide-solid">
        {collections &&
          collections.map((c) => (
            <ListLink
              key={c.id}
              name={c.name}
              href={`/collection/${c.id}`}
              tw="py-2"
            />
          ))}
        {hasOrganization && (
          <Link to="/collection/new-collection">
            <NeuButton tw="my-2">
              <i className="mi-add" /> New collection
            </NeuButton>
          </Link>
        )}
      </div>
    </div>
  );
}

export default CollectionList;
