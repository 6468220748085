import "twin.macro";
import { useShare } from "../../clients/apiHooks";
import { QueryRow } from "./QueryRow";
import { NeuButton } from "../generic/Neu";
import { Share } from "../../clients/types";

export function ShareQueries({
  share,
  onAddNewQuery,
  onEditQuery,
}: {
  share: Share;
  onAddNewQuery: null | (() => void);
  onEditQuery: null | ((queryId: string) => void);
}): JSX.Element | null {
  const { deleteShareQuery } = useShare(share.id);

  return (
    <div>
      <span tw="label text-grey-dark">Queries</span>
      <div tw="flex flex-col gap-4 my-2">
        <div>
          {share.queries
            .slice() //if you don't slice reverse() mutates the original array, which we do not want :)
            .reverse()
            .map((queries) => {
              return (
                <QueryRow
                  key={queries.shareQueryId}
                  query={queries}
                  deleteQuery={deleteShareQuery}
                  onEditQuery={onEditQuery}
                />
              );
            })}
        </div>
      </div>
      <div tw="flex mt-4 gap-4">
        <NeuButton
          disabled={!onAddNewQuery}
          onClick={onAddNewQuery ?? undefined}
        >
          <i className="mi-add" /> <span>Create new query</span>
        </NeuButton>
      </div>
    </div>
  );
}
