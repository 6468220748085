import "twin.macro";

function ImageSkeleton() {
  return (
    <div tw="flex animate-pulse  w-full">
      <div tw="w-72 h-72 bg-grey-light rounded-md "></div>
      <div tw="ml-2 w-8 h-72 bg-grey-light rounded-md "></div>
    </div>
  );
}

export default ImageSkeleton;
