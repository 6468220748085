import { useRecoilValue } from "recoil";
import { albumSharesSelector } from "../../clients/apiHooks";
import { ListLink } from "../navigation/ListLink";
import "twin.macro";

export interface AlbumSharedInListProps {
  collectionId: string;
  albumId: string;
}

export default function AlbumSharedInList({
  collectionId,
  albumId,
}: AlbumSharedInListProps) {
  const albumShares = useRecoilValue(
    albumSharesSelector({ collectionId: collectionId, albumId: albumId }),
  );
  if (!albumShares || albumShares.length === 0) {
    return null;
  }
  return (
    <div>
      <span tw="label">Shared In</span>
      {albumShares.map((share) => (
        <ListLink
          href={`/share/${share.id}`}
          name={share.name}
          key={share.id}
          tw="py-2"
        />
      ))}
    </div>
  );
}
