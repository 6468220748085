import "twin.macro";
import { FileUpload, UploadStatus } from "../../atoms";
import { useUploads } from "../../clients/apiHooks";
import { useEffect, useState } from "react";

interface UploadListProps {
  className?: string;
  current: boolean;
  albumId: string;
  collectionId: string;
}

const stateIcons = {
  [UploadStatus.FAILURE]: "mi-circle-error",
  [UploadStatus.IN_PROGRESS]: "mi-circle-arrow-up",
  [UploadStatus.NOT_STARTED]: "mi-circle",
  [UploadStatus.SUCCESS]: "mi-circle-check",
};

export function UploadList({
  className,
  current,
  albumId,
  collectionId,
}: UploadListProps) {
  const { atomFiles, removeFile } = useUploads(albumId, collectionId);
  const displayFiles = atomFiles.filter((f) => {
    if (current) {
      return f.albumId === albumId && f.collectionId === collectionId;
    } else {
      return f.albumId !== albumId || f.collectionId !== collectionId;
    }
  });

  return (
    <div className={className}>
      {displayFiles?.length ? <div tw="h-4" /> : null}
      {displayFiles.map((f) => (
        <Upload key={f.id} fileUpload={f} removeFile={removeFile} />
      ))}
    </div>
  );
}

interface UploadProps {
  fileUpload: FileUpload;
  removeFile: (filename: string) => void;
  timeoutMs?: number;
  transitionMs?: number;
}

function Upload({
  fileUpload,
  removeFile,
  timeoutMs = 10000,
  transitionMs = 1000,
}: UploadProps) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (fileUpload.state !== UploadStatus.SUCCESS) return;

    const visibleTimer = setTimeout(() => {
      setIsVisible(false);
    }, timeoutMs - transitionMs);

    const stateTimer = setTimeout(
      () => removeFile(fileUpload.file.name),
      timeoutMs,
    );

    return () => {
      clearTimeout(stateTimer);
      clearTimeout(visibleTimer);
    };
  }, [
    fileUpload.file.name,
    fileUpload.state,
    removeFile,
    timeoutMs,
    transitionMs,
  ]);

  return (
    <div
      className={`flex flex-row space-x-2 transition-opacity duration-${transitionMs} ease-in ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <i
        tw="text-xl"
        className={stateIcons[fileUpload.state] || "mi-circle-help"}
      />
      <span tw="text-base font-bold">{fileUpload.file.name}</span>
      <div tw="w-24 h-6 border-b border-grey" />
      <div
        tw="inline-block cursor-pointer"
        id={fileUpload.file.name}
        onClick={() => removeFile(fileUpload.file.name)}
      >
        <i
          tw="text-xl"
          className={
            fileUpload.state === UploadStatus.SUCCESS ? "mi-check" : "mi-delete"
          }
        />
      </div>
    </div>
  );
}
