import "twin.macro";

import { Link } from "react-router-dom";

import { ListLink } from "./ListLink";
import { useShares, useWorkspace } from "../../clients/apiHooks";
import { NeuButton } from "../generic/Neu";

function ShareList(): JSX.Element {
  const { hasOrganization } = useWorkspace();

  const { shares, userShares } = useShares();

  return (
    <div tw="p-4 w-72">
      {hasOrganization && <span tw="label">Share groups</span>}
      <div tw="divide-y divide-solid">
        {!!shares?.length &&
          shares.map((s) => (
            <ListLink
              href={`/share/${s.id}`}
              name={s.name}
              key={s.id}
              tw="py-2"
            />
          ))}
      </div>
      {hasOrganization && (
        <Link to="/share/new-share">
          <NeuButton tw="my-2">
            <i className="mi-add" /> New share group
          </NeuButton>
        </Link>
      )}
      {!!userShares?.length && (
        <div>
          <span tw="label my-2">Shared with me</span>
          <div tw="divide-y divide-solid">
            {userShares?.map((share) => (
              <ListLink
                href={`/share/${share.id}`} //TODO: when we have read-only share view page this needs to link there instead
                name={share.name}
                key={share.id}
                tw="py-2"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ShareList;
