import "twin.macro";

import { useEffect, useState } from "react";
import { AlbumsResult, AssetsResult, ShareDetails } from "../../clients/types";
import { pageSize, resetPageParam, SortOrder } from "../collection/AlbumList";
import { getShareAlbums, getShareAssets } from "../../clients/apiClient";
import { useSearchParams } from "react-router-dom";
import { getPage, Pagination } from "../generic/Pagination";
import { CheckboxRoundButton, NeuButton } from "../generic/Neu";
import { AlbumDisplayList, AssetsDisplayList } from "../generic/DisplayList";
import { useIntersectionObserver } from "../../hooks";

export function ShareContents({ share }: { share: ShareDetails }) {
  const shareId = share.id;

  const { ref } = useIntersectionObserver();

  const [shareAlbums, setShareAlbums] = useState<AlbumsResult>({
    albums: [],
    albumsCount: 0,
  });

  const [shareAssets, setShareAssets] = useState<AssetsResult>({
    assets: [],
    assetsCount: 0,
  });

  const albumPages = Math.max(Math.ceil(shareAlbums.albumsCount / pageSize), 1);

  const assetPages = Math.max(Math.ceil(shareAssets.assetsCount / pageSize), 1);

  const [sortOrder, setSortOrder] = useState<SortOrder>("ASC");
  const [isAlbumsChecked, setIsAlbumsChecked] = useState(true);
  const [isAssetsChecked, setIsAssetsChecked] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const page = getPage(searchParams);

  useEffect(() => {
    resetPageParam({ searchParams, setSearchParams, name: "page" });
  }, [share, isAlbumsChecked, isAssetsChecked]);

  useEffect(() => {
    if (!shareId) return;
    const abortController = new AbortController();
    (async () => {
      try {
        const fetchedAlbum = await getShareAlbums(
          shareId,
          pageSize,
          (page - 1) * pageSize,
          sortOrder,
          abortController.signal,
        );
        setShareAlbums({
          albums: fetchedAlbum.shareAlbums,
          albumsCount: fetchedAlbum.shareAlbumsCount,
        });
      } catch (error) {
        if (error instanceof Error && error.name === "AbortError") {
          // No-op if request aborted
        } else {
          throw error;
        }
      }
    })();

    return () => abortController.abort();
  }, [shareId, sortOrder, page, share]);

  useEffect(() => {
    if (!shareId || !isAssetsChecked) {
      setShareAssets({ assets: [], assetsCount: 0 });
      return;
    }
    const abortController = new AbortController();

    (async () => {
      try {
        const fetchedAsset = await getShareAssets(
          shareId,
          pageSize,
          (page - 1) * pageSize,
          sortOrder,
          abortController.signal,
        );

        setShareAssets({
          assets: fetchedAsset.shareAssets,
          assetsCount: fetchedAsset.shareAssetsCount,
        });
      } catch (error) {
        if (error instanceof Error && error.name === "AbortError") {
          // No-op if request aborted
        } else {
          throw error;
        }
      }
    })();

    return () => abortController.abort();
  }, [shareId, sortOrder, page, share, isAssetsChecked]);

  function handleSortOrder() {
    setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
  }

  return (
    <div>
      <span tw="label mb-4 mt-10">Shared albums and assets</span>
      {share.queries.length > 0 ? (
        <div tw="flex gap-4">
          <NeuButton onClick={() => handleSortOrder()} tw="mb-4">
            <i className="mi-sort" /> <span>Sort</span>
          </NeuButton>

          <CheckboxRoundButton
            id="albums"
            label="Albums"
            checked={isAlbumsChecked}
            onChange={() => setIsAlbumsChecked(!isAlbumsChecked)}
          />

          <CheckboxRoundButton
            id="assets"
            label="Assets"
            checked={isAssetsChecked}
            onChange={() => setIsAssetsChecked(!isAssetsChecked)}
          />
        </div>
      ) : (
        share.isOwner && (
          <div>
            Nothing has been shared with the group yet.
            <br />
            Create a new query to get started.
          </div>
        )
      )}
      <div tw="grid gap-4">
        {shareAlbums.albumsCount === 0 &&
          shareAssets.assetsCount === 0 &&
          share.queries.length > 0 && (
            <div>No albums or assets to share found.</div>
          )}
        {isAlbumsChecked &&
          (shareAlbums.albums.length > 0 ? (
            <div>
              <AlbumDisplayList
                ref={ref}
                albums={shareAlbums.albums}
                isCondensed={false}
                page={page}
                shareId={shareId}
              />
              {(shareAssets && shareAssets.assets.length === 0) ||
              !isAssetsChecked ? (
                <Pagination pages={albumPages} tw="mt-4" />
              ) : (
                albumPages > 1 && (
                  <NeuButton
                    onClick={() => setIsAssetsChecked(false)}
                    tw="mt-4"
                  >
                    see more albums
                  </NeuButton>
                )
              )}
            </div>
          ) : null)}

        {isAssetsChecked &&
          (shareAssets.assets.length > 0 ? (
            <div>
              <AssetsDisplayList assets={shareAssets.assets} />
              {shareAlbums.albums.length === 0 || !isAlbumsChecked ? (
                <Pagination pages={assetPages} tw="mt-4" />
              ) : (
                assetPages > 1 && (
                  <NeuButton
                    onClick={() => setIsAlbumsChecked(false)}
                    tw="mt-4"
                  >
                    see more assets
                  </NeuButton>
                )
              )}
            </div>
          ) : null)}
      </div>
    </div>
  );
}
