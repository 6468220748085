import { useCallback, useEffect, useState } from "react";
import {
  SuggestionInput,
  SuggestionInputProps,
} from "../generic/SuggestionInput";
import {
  getCollectionTagsKeys,
  getCollectionTagsValues,
} from "../../clients/apiClient";

export type CollectionKeyInputProps = {
  collectionId: string;
} & Omit<SuggestionInputProps, "suggestions" | "onSuggestionsRequested">;

export function CollectionKeyInput({
  collectionId,
  ...rest
}: CollectionKeyInputProps) {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionsNeeded, setSuggestionsNeeded] = useState(false);
  const { value } = rest;

  useEffect(() => {
    if (!suggestionsNeeded) {
      return;
    }
    const abort = new AbortController();
    (async () => {
      const result = await getCollectionTagsKeys(
        collectionId,
        value,
        abort.signal,
      );
      setSuggestions(result.keys.filter((val) => val !== value));
    })();
    return () => abort.abort();
  }, [value, suggestionsNeeded, collectionId]);

  return (
    <SuggestionInput
      {...rest}
      onSuggestionsRequested={setSuggestionsNeeded}
      suggestions={suggestions}
    />
  );
}

export type CollectionValueInputProps = {
  collectionId: string;
  keyValue: string;
} & Omit<SuggestionInputProps, "suggestions" | "onSuggestionsRequested">;

export function CollectionValueInput({
  collectionId,
  keyValue,
  ...rest
}: CollectionValueInputProps) {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionsNeeded, setSuggestionsNeeded] = useState(false);
  const { value } = rest;

  useEffect(() => {
    if (keyValue === "" || !suggestionsNeeded) {
      return;
    }
    const abort = new AbortController();
    (async () => {
      const result = await getCollectionTagsValues(
        collectionId,
        keyValue,
        value,
        abort.signal,
      );
      setSuggestions(result.values.filter((val) => val !== value));
    })();
    return () => abort.abort();
  }, [value, keyValue, suggestionsNeeded, collectionId]);

  return (
    <SuggestionInput
      {...rest}
      onSuggestionsRequested={setSuggestionsNeeded}
      suggestions={suggestions}
    />
  );
}
