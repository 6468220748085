import "twin.macro";
import { Helmet } from "react-helmet";
import ErrorBoundary from "../generic/ErrorBoundary";
import { AlbumCol, MarginWrapper, MenuCol } from "../generic/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { BackButton } from "../generic/Neu";
import AlbumView from "../album/AlbumView";
import { useShare } from "../../clients/apiHooks";
import { ShareContents } from "./ShareContents";
import { Modal } from "../generic";
import { Suspense } from "react";
import AssetDetails from "../album/AssetDetails";

export function ShareAlbumView() {
  const { shareId, assetId, albumId, collectionId } = useParams<{
    shareId: string;
    assetId: string;
    albumId: string;
    collectionId: string;
  }>();
  const { share } = useShare(shareId);

  const navigate = useNavigate();
  const handleCloseAssetDetails = () => {
    navigate(-1);
  };

  return (
    <ErrorBoundary
      fallback={<div>Something went wrong loading the share...</div>}
    >
      <Helmet>
        <title> {share?.name} | Exrepo</title>
      </Helmet>
      <div tw="max-w-screen-fhd flex mx-auto py-4 px-3">
        <MenuCol active={true}>
          <span tw="label">Share</span>
          <h1 tw="w-full sm:mb-4">{share?.name}</h1>
          {share && <ShareContents share={share} />}
        </MenuCol>
        <AlbumCol active={true}>
          <MarginWrapper isCenter={true}>
            <BackButton tw="mb-8" />
            <AlbumView shareId={shareId} />
          </MarginWrapper>
        </AlbumCol>
      </div>
      {assetId ? (
        <Modal>
          <Suspense fallback={<div />}>
            <AssetDetails
              tw="w-full h-full"
              id={assetId}
              albumId={albumId}
              collectionId={collectionId}
              onSelect={handleCloseAssetDetails}
              onClose={handleCloseAssetDetails}
              hideDeleteButton
            />
          </Suspense>
        </Modal>
      ) : null}
    </ErrorBoundary>
  );
}
