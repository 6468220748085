import React, { Suspense, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useCollection, useCollections } from "../../clients/apiHooks";

import AlbumList from "./AlbumList";
import AlbumView from "../album/AlbumView";
import NewAlbumView from "../album/NewAlbumView";
import AssetDetails from "../album/AssetDetails";

import { Modal } from "../generic";
import { BackButton, NeuButton } from "../generic/Neu";
import ErrorBoundary from "../generic/ErrorBoundary";
import { EditableTitle } from "../album/EditableTitle";
import { Helmet } from "react-helmet";
import { MainCol, MarginWrapper, MenuCol } from "../generic/Layout";
import tw, { styled } from "twin.macro";
import { getAlbums } from "../../clients/apiClient";
import { CollectionShareList } from "../navigation/LandingPage";

const AlbumCol = styled.div((props: { active?: boolean }) => [
  tw`transition-all ease-out relative`,
  props.active ? tw`w-full sm:w-[80%]` : tw`w-0`,
]);

function CollectionView(): JSX.Element | null {
  const { collectionId, albumId, assetId } = useParams<{
    collectionId: string;
    albumId?: string;
    assetId?: string;
  }>();
  const { collection, patchCollection } = useCollection(collectionId);
  const { deleteCollection } = useCollections();

  const [albumCount, setAlbumCount] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [showDeleteDialogOnNextDelete, setShowDeleteDialogOnNextDelete] =
    useState(true);

  async function handleSubmit(newCollectionName: string) {
    if (!collection) {
      return;
    }
    const newValues = { name: newCollectionName, collectionId: collection.id };
    await patchCollection(newValues);
  }
  const navigate = useNavigate();
  const handleCloseAssetDetails = () => {
    navigate(-1);
  };

  async function handleDeleteCollection() {
    if (!collectionId) {
      console.error("No collection to delete");
      return null;
    }
    deleteCollection(collectionId);
    navigate("/collection");
  }

  useEffect(() => {
    setIsLoading(true);
    const abortController = new AbortController();
    (async () => {
      try {
        if (collection?.id) {
          const fetchedAlbums = await getAlbums(
            collection.id,
            0,
            0,
            "",
            "ASC",
            abortController.signal,
          );
          setAlbumCount(fetchedAlbums.albumsCount);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching albums in CollectionView", error);
        setIsLoading(false);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [collectionId, collection, albumId]);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    scrollContainerRef?.current?.scrollTo(0, 0);
  }, [albumId, collectionId, scrollContainerRef]);

  const loc = useLocation();
  const isNewAlbumInput = loc.pathname.endsWith("/new-album");
  const isAlbumView = !!albumId || isNewAlbumInput;
  const isCollectionView = !!collectionId && !albumId && !isNewAlbumInput;

  useEffect(() => {
    setShowDeleteDialogOnNextDelete(true);
  }, [albumId]);

  return (
    <ErrorBoundary
      fallback={<div>Something went wrong loading the collection...</div>}
    >
      <Helmet>
        <title>
          {collection?.name ? `${collection.name} | Exrepo` : "Exrepo"}
        </title>
      </Helmet>
      {collection && (
        <div tw="max-w-screen-fhd flex mx-auto">
          <MenuCol active={!isAlbumView}>
            <div tw="fixed top-0 h-screen overflow-y-auto">
              {!isAlbumView && <CollectionShareList />}
            </div>
          </MenuCol>

          <MainCol active={isCollectionView}>
            <MarginWrapper isCenter={isCollectionView} hasMenu>
              {collection && (
                <>
                  {isCollectionView && <BackButton tw="mb-8 sm:hidden" />}
                  <div tw="flex flex-col sm:flex-row gap-4 justify-between">
                    <div>
                      <span tw="label">Collection</span>
                      <div tw="w-full">
                        <div tw="text-3xl sm:text-7xl">
                          <EditableTitle
                            tw="sm:mb-4"
                            onUpdate={handleSubmit}
                            title={collection.name}
                          />
                        </div>
                      </div>
                    </div>
                    {isCollectionView && (
                      <div tw="text-sm mb-6 sm:mr-24">
                        <NeuButton
                          tw="bg-danger"
                          icon="mi-delete"
                          disabled={!!albumCount || isLoading}
                          onClick={handleDeleteCollection}
                        >
                          Delete Collection
                        </NeuButton>
                      </div>
                    )}
                  </div>
                  <AlbumList
                    collectionId={collection.id}
                    isCondensed={isAlbumView}
                  />
                </>
              )}
            </MarginWrapper>
          </MainCol>

          <AlbumCol active={isAlbumView}>
            {isNewAlbumInput && (
              <MarginWrapper isCenter hasMenu ref={scrollContainerRef}>
                <NewAlbumView />
              </MarginWrapper>
            )}
            {albumId ? (
              <Suspense fallback={<div />}>
                <MarginWrapper isCenter hasMenu ref={scrollContainerRef}>
                  {isAlbumView && <BackButton tw="mb-8" />}
                  <AlbumView />
                </MarginWrapper>
              </Suspense>
            ) : null}
          </AlbumCol>
        </div>
      )}
      {assetId ? (
        <Modal>
          <Suspense fallback={<div />}>
            <AssetDetails
              tw="w-full h-full"
              id={assetId}
              albumId={albumId}
              collectionId={collectionId}
              onSelect={handleCloseAssetDetails}
              onClose={handleCloseAssetDetails}
              showDeleteDialogOnNextDelete={showDeleteDialogOnNextDelete}
              setShowDeleteDialogOnNextDelete={setShowDeleteDialogOnNextDelete}
            />
          </Suspense>
        </Modal>
      ) : null}
    </ErrorBoundary>
  );
}

export default CollectionView;
