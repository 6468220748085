import { createContext, useContext, useCallback } from "react";
import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import {
  uploadFile,
  getWorkspace,
  createAlbum,
  deleteAsset,
  patchAlbum,
  updateAssetMetadata,
  createCollection,
  patchCollection,
  getAlbum,
  deleteAlbum,
  getAsset,
  createShare,
  getShares,
  getAssets,
  patchShare,
  inviteToShare,
  upsertShareQuery,
  deleteShareQuery,
  getSharesForUser,
  getSharesForAlbum,
  getShare,
  getSharesForAsset,
  deleteCollection,
  deleteShare,
  unInviteFromShare,
} from "./apiClient";

import {
  activeUploads,
  FileUpload,
  selectedAssetDictionary,
  UploadStatus,
} from "../atoms";
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";
import {
  Album,
  Asset,
  Collection,
  CollectionBase,
  InviteToShareParameters,
  PatchAlbumParameters,
  PatchCollectionParameters,
  PatchShareParameters,
  Share,
  ShareQueryUpsert,
  Workspace,
  SharesForAsset,
  UnInviteFromShareParameters,
} from "./types";

export function useWorkspace(): {
  collections: CollectionBase[];
  hasOrganization: boolean;
  loading: boolean;
  error: boolean;
} {
  // TODO: "The" react way would be Suspense and ErrorBoundary..
  const loadable = useRecoilValueLoadable(workspaceAtom);
  switch (loadable.state) {
    case "hasValue":
      return {
        collections: loadable.contents?.collections ?? [],
        hasOrganization: loadable.contents?.hasOrganization ?? false,
        loading: false,
        error: false,
      };
    case "loading":
      return {
        collections: [],
        hasOrganization: false,
        loading: true,
        error: false,
      };
    case "hasError":
      return {
        collections: [],
        hasOrganization: false,
        loading: false,
        error: true,
      };
  }
}

export function useCollections() {
  const [, setWorkspace] = useRecoilState(workspaceAtom);

  const _refreshWorkspace = async () => {
    const workspace = await getWorkspace();
    setWorkspace(workspace);
  };

  const _createCollection = async (name: string): Promise<CollectionBase> => {
    const collection = await createCollection(name);
    _refreshWorkspace();
    return collection;
  };

  const _deleteCollection = async (collectionId: string) => {
    await deleteCollection(collectionId);
    _refreshWorkspace();
  };

  return {
    deleteCollection: _deleteCollection,
    refreshWorkspace: _refreshWorkspace,
    createCollection: _createCollection,
  };
}

export function useCollection(collectionId?: string) {
  const collection = useRecoilValue(collectionSelector(collectionId));
  const refreshCollection = useRecoilRefresher_UNSTABLE(
    collectionSelector(collectionId),
  );

  const _patchCollection = async (newValues: PatchCollectionParameters) => {
    patchCollection(newValues.name, newValues.collectionId)
      .then(() => {
        refreshCollection();
      })
      .catch((err) => {
        console.error(err);
        refreshCollection();
      });
  };

  return {
    patchCollection: _patchCollection,
    collection: collection,
  };
}

export const CollectionContext = createContext<string | null>(null);
export const AlbumContext = createContext<string | null>(null);

export function useSelectedAssets() {
  const [selectedAssets, setSelectedAssets] = useRecoilState(
    selectedAssetDictionary,
  );

  function toggleSelectAsset(assetId: string, currentState: boolean) {
    setSelectedAssets((prevState) => ({
      ...prevState,
      [assetId]: { isSelected: !currentState },
    }));
  }

  function getSelectedAssets() {
    return Object.keys(selectedAssets).filter(
      (id) => selectedAssets[id]?.isSelected,
    );
  }
  function getAssetState(assetId: string) {
    if (selectedAssets[assetId]) {
      return selectedAssets[assetId];
    }
    return { isSelected: false };
  }
  return {
    selectedAssets,
    getSelectedAssets,
    getAssetState,
    toggleSelectAsset,
  };
}

export function useUploads(albumId: string, collectionId: string) {
  const [atomFiles, setAtomFiles] = useRecoilState(activeUploads);
  const { refreshAssets } = useAssets();

  function handleFileSelect(evt: React.FormEvent<HTMLInputElement>) {
    const fileInput = Array.from(evt.currentTarget.files || []);
    const newFiles: FileUpload[] = fileInput
      .filter(
        (f: File) =>
          !atomFiles.some(
            (enquedFile) =>
              enquedFile.state !== UploadStatus.FAILURE &&
              enquedFile.file.name === f.name,
          ),
      )
      .map((f: File) => ({
        file: f,
        albumId: albumId,
        collectionId: collectionId,
        state: UploadStatus.IN_PROGRESS,
        id: uuidv4(),
      }));

    setAtomFiles(atomFiles.concat(newFiles));

    newFiles.forEach((fileUpload) => {
      uploadFile(fileUpload)
        .then((response) => {
          setAtomFiles((currentFiles) => {
            const fileIndex = currentFiles.indexOf(fileUpload);
            const newAtomFiles = [
              ...currentFiles.slice(0, fileIndex),
              { ...fileUpload, state: UploadStatus.SUCCESS },
              ...currentFiles.slice(fileIndex + 1),
            ];
            return newAtomFiles;
          });
        })
        .catch((error) => {
          console.error(error);
          setAtomFiles((currentFiles) => {
            const fileIndex = currentFiles.indexOf(fileUpload);
            const newAtomFiles = [
              ...currentFiles.slice(0, fileIndex),
              { ...fileUpload, state: UploadStatus.FAILURE },
              ...currentFiles.slice(fileIndex + 1),
            ];
            return newAtomFiles;
          });
        })
        .finally(async () => {
          refreshAssets();
        });
    });
  }

  const removeFile = useCallback(
    (filename: string) => {
      setAtomFiles((atomFiles) =>
        atomFiles.filter((fileUpload) => fileUpload.file.name !== filename),
      );
    },
    [setAtomFiles],
  );

  return { atomFiles, handleFileSelect, removeFile };
}

const albumsRefreshAtom = atom({
  key: "AlbumsRefresh",
  default: 0,
});

export function useAlbumsHook(collectionId: string) {
  const refreshAlbums = useRecoilCallback(({ set }) => () => {
    set(albumsRefreshAtom, (value) => value + 1);
  });

  const _createAlbum = async (name: string): Promise<Album> => {
    const album = await createAlbum(collectionId, name);
    refreshAlbums();
    return album;
  };

  const _deleteAlbum = async (albumId: string) => {
    await deleteAlbum(collectionId, albumId);
    refreshAlbums();
  };

  return {
    createAlbum: _createAlbum,
    deleteAlbum: _deleteAlbum,
    refreshAlbums,
  };
}

export function useAlbumsRefresh(): unknown {
  return useRecoilValue(albumsRefreshAtom);
}

export function useAlbumHook(collectionId: string, albumId: string) {
  const refreshAlbum = useRecoilRefresher_UNSTABLE(
    albumSelector({ collectionId, albumId }),
  );
  const refreshAlbumSharedIn = useRecoilRefresher_UNSTABLE(
    albumSharesSelector({ collectionId: collectionId, albumId: albumId }),
  );
  const { refreshAlbums } = useAlbumsHook(collectionId);
  const { refreshAssets } = useAssets();

  const _patchAlbum = async (newValues: PatchAlbumParameters) => {
    patchAlbum(collectionId, albumId, newValues)
      .then(() => {
        refreshAlbum();
        refreshAlbums();
        refreshAssets();
        refreshAlbumSharedIn();
      })
      .catch((err) => {
        console.error(err);
        refreshAlbum();
        refreshAlbums();
        refreshAssets();
        refreshAlbumSharedIn();
      });
  };

  return { patchAlbum: _patchAlbum };
}

/*
  These atoms (workspace, albums and assets) correspond to our backend entities and should follow those definitions
  closely.
  Everything else we need (for convenience in the UI) should be derived selectors from those.
*/

export const workspaceAtom = atom<Workspace | null>({
  key: "Workspace",
  default: selector<Workspace | null>({
    key: "Workspace/Default",
    get: async () => {
      return await getWorkspace();
    },
  }),
});

const sharesAtom = atom<Share[] | null>({
  key: "Shares",
  default: selector<Share[] | null>({
    key: "Shares/Default",
    get: async () => {
      return await getShares();
    },
  }),
});

const userSharesAtom = atom<Share[] | null>({
  key: "UserShares",
  default: selector<Share[] | null>({
    key: "UserShares/Default",
    get: async () => {
      return await getSharesForUser();
    },
  }),
});

export const albumSelector = selectorFamily<
  Album,
  { collectionId: string; albumId: string }
>({
  key: "AlbumsById",
  get:
    ({ collectionId, albumId }) =>
    () => {
      return getAlbum(collectionId, albumId);
    },
});

export const albumSharesSelector = selectorFamily<
  Share[],
  { collectionId: string; albumId: string }
>({
  key: "AlbumSharesById",
  get:
    ({ collectionId, albumId }) =>
    () => {
      return getSharesForAlbum(collectionId, albumId);
    },
});

export const albumAssetPreviewSelector = selectorFamily<
  Asset[],
  { collectionId: string; albumId: string }
>({
  key: "AlbumAssetsFirstPage",
  get:
    ({ collectionId, albumId }) =>
    async () => {
      const result = await getAssets(
        collectionId,
        albumId,
        undefined,
        5,
        0,
        "DESC",
      );
      return result.assets;
    },
});

function useAssets() {
  const refreshAssets = useRecoilCallback(({ set }) => () => {
    set(assetsRefreshAtom, (value) => value + 1);
  });

  return { refreshAssets };
}

export function useAsset(params: {
  assetId: string;
  albumId?: string;
  collectionId?: string;
}) {
  const ctxAlbumId = useContext(AlbumContext);
  const ctxCollectionId = useContext(CollectionContext);

  const assetId = params.assetId;
  const collectionId = params.collectionId || ctxCollectionId;
  const albumId = params.albumId || ctxAlbumId;

  const asset = useRecoilValue(
    assetSelector({
      collectionId: collectionId || "",
      albumId: albumId || "",
      assetId: params.assetId,
    }),
  );

  const { refreshAssets } = useAssets();
  const refreshAssetSharedIn = useRecoilRefresher_UNSTABLE(
    assetSharesSelector({
      collectionId: collectionId || "",
      albumId: albumId || "",
      assetId: assetId,
    }),
  );

  const _deleteAsset = useCallback(async () => {
    if (!collectionId || !albumId) {
      return;
    }
    await deleteAsset(collectionId, albumId, assetId);
    refreshAssets();
  }, [collectionId, albumId, assetId, refreshAssets]);

  const _updateAssetMetadata = useCallback(
    async (metadata) => {
      if (!collectionId || !albumId) {
        return;
      }
      await updateAssetMetadata(metadata, collectionId, albumId, assetId);
      refreshAssets();
      refreshAssetSharedIn();
    },
    [collectionId, albumId, assetId, refreshAssets],
  );

  return {
    asset,
    deleteAsset: _deleteAsset,
    updateAssetMetadata: _updateAssetMetadata,
  };
}

const assetsRefreshAtom = atom({
  key: "AssetsRefresh",
  default: 0,
});

export function useAssetsRefresh(): unknown {
  return useRecoilValue(assetsRefreshAtom);
}

export const assetSelector = selectorFamily<
  Asset | null,
  { collectionId: string; albumId: string; assetId: string }
>({
  key: "SingleAssetSelector",
  get:
    ({ collectionId, albumId, assetId }) =>
    async ({ get }) => {
      get(assetsRefreshAtom);

      const asset = await getAsset(collectionId, albumId, assetId);
      return asset;
    },
});

export const assetSharesSelector = selectorFamily<
  SharesForAsset,
  { collectionId: string; albumId: string; assetId: string }
>({
  key: "SharesForAsset",
  get:
    ({ collectionId, albumId, assetId }) =>
    () => {
      return getSharesForAsset(collectionId, albumId, assetId);
    },
});

export const collectionSelector = selectorFamily<
  Collection | null,
  string | null | undefined
>({
  key: "Collection",
  get:
    (collectionId) =>
    async ({ get }) => {
      const collections = get(workspaceAtom);
      const collection = collections?.collections.filter(
        (collection) => collection.id === collectionId,
      );
      if (!collectionId || !collection?.length) {
        return null;
      }

      return {
        name: collection[0].name,
        id: collectionId,
      };
    },
});

export function useShares() {
  const [shares, setShares] = useRecoilState(sharesAtom);
  const [userShares, setUserShares] = useRecoilState(userSharesAtom);

  const _refreshShares = async () => {
    const shares = await getShares();
    const userShares = await getSharesForUser();
    setUserShares(userShares);
    setShares(shares);
  };

  const _createShare = async (name: string): Promise<Share> => {
    const share = await createShare(name);
    _refreshShares();
    return share;
  };

  return {
    createShare: _createShare,
    refreshShares: _refreshShares,
    userShares: userShares,
    shares,
  };
}

export function useShare(shareId?: string) {
  const queryClient = useQueryClient();

  const shareQuery = useQuery({
    queryKey: ["shareById", shareId],
    queryFn: shareId ? () => getShare(shareId) : skipToken,
    throwOnError: true,
  });
  const { refreshShares } = useShares();

  // FIXME: use ReactQuery mutations here?
  const _deleteShareQuery = async (payload: {
    shareQueryId: string;
    shareId: string;
  }) => {
    deleteShareQuery(payload.shareQueryId, payload.shareId)
      .then(() => {
        shareQuery.refetch();
      })
      .catch((err) => {
        console.error(err);
        shareQuery.refetch();
      });
  };

  const _upsertShareQuery = async (payload: ShareQueryUpsert) => {
    upsertShareQuery(payload)
      .then(() => {
        shareQuery.refetch();
      })
      .catch((err) => {
        console.error(err);
        shareQuery.refetch();
        throw err;
      });
  };

  const _patchShare = async (payload: PatchShareParameters) => {
    patchShare(payload.name, payload.shareId)
      .then(() => {
        shareQuery.refetch();
        refreshShares();
      })
      .catch((err) => {
        console.error(err);
        shareQuery.refetch();
        refreshShares();
      });
  };

  const _inviteToShare = async (payload: InviteToShareParameters) => {
    inviteToShare(payload.emails, payload.shareId)
      .then(() => {
        shareQuery.refetch();
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  const _unInviteFromShare = useMutation({
    mutationFn: (payload: UnInviteFromShareParameters) =>
      unInviteFromShare(payload.email, payload.shareId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["shareById", shareId],
      });
    },
  });

  const _deleteShare = async (shareId: string) => {
    try {
      await deleteShare(shareId);
    } catch (err) {
      console.error(err);
      throw err;
    }
    refreshShares();
  };

  return {
    inviteToShare: _inviteToShare,
    unInviteFromShare: _unInviteFromShare,
    patchShare: _patchShare,
    upsertShareQuery: _upsertShareQuery,
    deleteShareQuery: _deleteShareQuery,
    share: shareQuery.data,
    deleteShare: _deleteShare,
  };
}
